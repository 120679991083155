<template>
  <div>
    <div class="content content-full content-boxed">
      <div v-show="errorAction.create" class="alert alert-danger">{{ errorAction.uploadCsv }}</div>
      <div class="block block-rounded">
        <div class="block-content">
          <h3>CSV Import</h3>
          <div v-if="!hasUploaded">
            <p>
              Use this tool to upload entity data from a CSV file. Please ensure your CSV file uses headings as defined in
              <a href="#">this template</a>.
            </p>
            <div class="form-group">
              <input type="file" name="csv" accept=".csv" @change="onFileSelect" />
            </div>
            <div class="text-right pb-4">
              <button type="submit" class="btn btn-primary" :disabled="loadingAction.uploadCsv" @click="onSubmit">
                <span v-if="loadingAction.uploadCsv"><i class="fa fa-spin fa-spinner mr-1"></i> Uploading...</span>
                <span v-else><i class="fa fa-upload mr-1"></i> Upload</span>
              </button>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-success mb-3">
              <i class="fa fa-fw fa-check-circle"></i> <strong>Import completed successfully.</strong> {{ results.entities.length }} out of
              {{ results.totalRows }} rows were imported.
            </div>
            <table class="table table-borderless table-vcenter table-responsive">
              <thead>
                <tr>
                  <th>Company Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="entity in results.entities" :key="entity._id">
                  <td>{{ entity.legalName }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-right pb-4">
              <router-link class="btn btn-primary" :to="{ name: 'entity-list' }">Go to Entities</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EntityCsvImport',
  data() {
    return {
      file: {},
      hasUploaded: false,
      results: []
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'entity/loadingAction',
      errorAction: 'entity/errorAction'
    })
  },
  methods: {
    ...mapActions({
      uploadCsv: 'entity/uploadCsv'
    }),
    onFileSelect(e) {
      const { name, files } = e.target;

      const formData = new FormData();

      formData.append(name, files[0], files[0].name);

      this.file = {
        name: files[0].name,
        form: formData
      };
    },
    async onSubmit() {
      this.hasUploaded = false;
      try {
        this.results = await this.uploadCsv({ data: this.file.form });
      } catch (e) {
        this.results = [];
      }
      this.hasUploaded = true;
    }
  }
};
</script>
